<template>
    <div class="single-file-alert alert">
        <div class="single-file-box alert_box">
            <h3>쿠폰 등록이 완료되었습니다.</h3>
            <div class="single-file-check">
                <p @click="$emit('closeFinished')">확인</p>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  setup () {}
});
</script>

<style src="@/assets/css/content2.css"></style>
<style src="@/assets/css/font.css"></style>
