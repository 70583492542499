<template>
    <div class="wrap" style="padding-bottom: 100px; overflow: auto">
        <alert-modal v-if="isAlertModal" :alertText="alertText" @closeModal="closeAlertModal()" />
        <CouponFinished v-if="ShowFinished" @closeFinished="ShowFinished = false" />
        <Aside v-if="asideStatus" @closeAside="asideStatus = false" />
        <div class="space_pc">
            <section class="main coupon">
                <div class="main_box">
                    <div class="main_box_title">
                        <h1 style="margin-bottom: 0px">쿠폰</h1>
                    </div>
                    <div class="coupon_input_wrap">
                        <input
                            type="text"
                            class="coupon_input_box"
                            placeholder="발급받은 쿠폰 번호를 입력해주세요."
                            maxlength="8"
                            v-model="couponCode"
                            @keyup.enter="insertCoupon()"
                        />
                        <button class="add_btn" @click="insertCoupon()">등록</button>
                    </div>
                    <span :class="errorText ? 'cupoon_error_text' : 'non_cupoon_error_text'">{{ errorText }}</span>

                    <div class="coupon_img_box" v-if="getCouponList.length > 0" style="flex-wrap: wrap">
                        <div v-for="(item, index) in getCouponList" :key="index" style="margin-bottom: 30px">
                            <div class="coupon_txt">
                                <span class="dday" v-if="endDay(item.endDate) >= 0 && item.dynamicUseAt === 'N'"
                                >D-{{ endDay(item.endDate) }}</span
                                >
                                <span class="dday" v-else-if="endDay(item.dyEndDate) >= 0 && item.dynamicUseAt === 'Y'"
                                >D-{{ endDay(item.dyEndDate) }}</span
                                >
                                <span v-else>만료</span>
                                <h3>{{ item.couponNm }}</h3>
                                <p class="coupon_content">{{ item.couponContent }}</p>
                                <div class="cupon_info_group">
                                    <p class="date_info" v-if="item.dynamicUseAt === 'N'">
                                        {{ dateTime(item.startDate) }} ~ {{ endDateTime(item.endDate) }} 사용
                                    </p>
                                    <p class="date_info" v-if="item.dynamicUseAt === 'Y'">
                                        {{ dateTime(item.dyStartDate) }} ~ {{ endDateTime(item.dyEndDate) }} 사용
                                    </p>
                                    <button class="coupon_use_btn" @click="goSpace(item)">사용하기</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else>
                        <div class="empty_area">
                            <img src="/media/img/icon_coupon_52.png" alt="" />
                            <p style="margin-bottom: 0px; border: 0px; background: #151515; color: rgba(255, 255, 255, 0.6)">
                                등록된 쿠폰이 없습니다.
                            </p>
                        </div>
                    </div>
                    <div class="regi_bottom">
                        <h5 style="margin-bottom: 0px">유의사항</h5>
                        <ul style="padding-left: 0px">
                            <li>
                                <span></span>
                                <p style="margin-bottom: 0px">쿠폰은 결제시 선택하여 1개만 사용 가능합니다.</p>
                            </li>
                            <li>
                                <span></span>
                                <p style="margin-bottom: 0px">유효기간이 만료된 쿠폰은 사용 불가하오니 만료 전 사용해주세요.</p>
                            </li>
                            <li>
                                <span></span>
                                <p style="margin-bottom: 0px">동일 쿠폰은 인당 1개 한정 발급됩니다.</p>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
        </div>
        <!-- 모바일 -->
        <div class="space_mo">
            <header class="header mo_header">
                <div class="header_box">
                    <div class="logo">
                        <a>
                            <img src="/media/images/icon_back_24.svg" @click="linkPlayer()" />
                            <h2 style="margin-bottom: 0px">쿠폰</h2>
                        </a>
                    </div>
                    <div class="right_menu">
                        <div class="alarm_box">
                            <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                @click="mobileAlarm"
                            >
                                <path
                                    d="M9.02759 19.7773C9.02759 20.5181 9.46002 21.9996 11.1897 21.9996C12.9195 21.9996 13.3519 20.5181 13.3519 19.7773"
                                    stroke="white"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                                <path
                                    d="M2.00024 18.666H20.3786"
                                    stroke="white"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                                <path
                                    d="M3.62183 18.1113V10.3335C3.62183 7.92613 5.13534 3.11133 11.1894 3.11133C17.2434 3.11133 18.7569 8.2965 18.7569 10.8891V18.1113"
                                    stroke="white"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                                <ellipse
                                    v-if="$store.state.noticeCount !== 0"
                                    cx="17.6756"
                                    cy="6.44444"
                                    rx="4.32431"
                                    ry="4.44444"
                                    fill="#FF1150"
                                />
                            </svg>
                        </div>
                        <div class="toggle">
                            <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                @click="showAside"
                            >
                                <g clip-path="url(#clip0_2231_20993)">
                                    <path
                                        d="M24 20L6 20L6 18L24 18L24 20ZM24 13L12 13L12 11L24 11L24 13ZM24 6L6 6L6 4L24 4L24 6Z"
                                        fill="white"
                                    />
                                </g>
                                <defs>
                                    <clipPath id="clip0_2231_20993">
                                        <rect width="24" height="24" fill="white" transform="translate(24 24) rotate(180)" />
                                    </clipPath>
                                </defs>
                            </svg>
                        </div>
                    </div>
                </div>
            </header>
            <section class="main coupon">
                <div class="main_box">
                    <div class="coupon_input_wrap">
                        <input
                            type="text"
                            class="coupon_input_box"
                            placeholder="발급받은 쿠폰 번호를 입력해주세요."
                            maxlength="8"
                            v-model="couponCode"
                            @keyup.enter="insertCoupon()"
                        />
                        <button class="add_btn" @click="insertCoupon()">등록</button>
                    </div>
                    <span :class="errorText ? 'cupoon_error_text' : 'non_cupoon_error_text'">{{ errorText }}</span>

                    <div class="coupon_img_box" v-if="getCouponList.length > 0" style="flex-wrap: wrap">
                        <div v-for="(item, index) in getCouponList" :key="index" style="margin-bottom: 30px">
                            <div class="coupon_txt">
                                <span class="dday" v-if="endDay(item.endDate) >= 0 && item.dynamicUseAt === 'N'"
                                >D-{{ endDay(item.endDate) }}</span
                                >
                                <span class="dday" v-else-if="endDay(item.dyEndDate) >= 0 && item.dynamicUseAt === 'Y'"
                                >D-{{ endDay(item.dyEndDate) }}</span
                                >
                                <span v-else>만료</span>
                                <h3>{{ item.couponNm }}</h3>
                                <p class="coupon_content">{{ item.couponContent }}</p>
                                <div class="cupon_info_group">
                                    <p class="date_info" v-if="item.dynamicUseAt === 'Y'">
                                        {{ dateTime(item.dyStartDate) }} ~ {{ endDateTime(item.dyEndDate) }} 사용
                                    </p>
                                    <p class="date_info" v-if="item.dynamicUseAt === 'N'">
                                        {{ dateTime(item.startDate) }} ~ {{ endDateTime(item.endDate) }} 사용
                                    </p>
                                    <button class="coupon_use_btn" @click="goSpace(item)">사용하기</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else>
                        <div class="empty_area">
                            <img src="/media/img/empty_coupon.png" alt="" />
                            <p style="margin-bottom: 0px; border: 0px; background: #151515; color: rgba(255, 255, 255, 0.6)">
                                등록된 쿠폰이 없습니다.
                            </p>
                        </div>
                    </div>
                    <div class="regi_bottom">
                        <h5 style="margin-bottom: 0px">유의사항</h5>
                        <ul style="padding-left: 0px">
                            <li>
                                <span></span>
                                <p style="margin-bottom: 0px">쿠폰은 결제시 선택하여 1개만 사용 가능합니다.</p>
                            </li>
                            <li>
                                <span></span>
                                <p style="margin-bottom: 0px">유효기간이 만료된 쿠폰은 사용 불가하오니 만료 전 사용해주세요.</p>
                            </li>
                            <li>
                                <span></span>
                                <p style="margin-bottom: 0px">동일 쿠폰은 인당 1개 한정 발급됩니다.</p>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
import CouponFinished from '@/components/modal/Coupon/Finished.vue';
import { defineComponent } from '@vue/composition-api';
import Aside from '@/layout/aside/Aside.vue';
import VueCookies from 'vue-cookies';
import router from '@/router';
import axios from '@/service/axios';
import dayjs from 'dayjs';
import AlertModal from '@/components/modal/Space/AlertModal.vue';
import { orderBy } from 'lodash';
export default defineComponent({
  name: 'coupon',
  data () {
    return {
      ShowInsert: false,
      ShowFinished: false,
      couponList: [],
      today: dayjs().format('YY.MM.DD'),
      asideStatus: false,
      couponCode: '',
      errorText: '',
      alertText: '',
      isAlertModal: false,
      isDisable: false
    };
  },
  created () {
    this.$store.commit('coupon/setSelectCupon', false);
    this.$store.commit('coupon/setCuponInfo', null);
  },
  components: {
    CouponFinished,
    Aside,
    'alert-modal': AlertModal
  },
  methods: {
    insertCoupon () {
      if (!this.isDisable) {
        this.isDisable = true;
        var userId = this.$cookies.get('userId');
        var couponCode = this.couponCode;
        var aToken = this.$cookies.get('aToken');
        var headers = { 'X-AUTH-TOKEN': aToken };

        axios
          .post('/api/product/insertCoupon', { userId, couponCode }, { headers })
          .then(res => {
            if (res.data.resultCd === '0000') {
              var result = res.data.result;
              if (result === '쿠폰 등록이 성공적으로 완료 되었습니다.') {
                this.errorText = '';
                this.couponCode = '';
                this.alertText = '등록이 완료되었습니다.';
                this.isAlertModal = true;
              } else if (result === '존재하지 않는 쿠폰 입니다.') {
                this.errorText = '유효하지 않은 쿠폰번호입니다.';
              } else {
                this.errorText = result;
              }
            }
          })
          .catch(err => {
            console.log(err);
          })
          .finally(() => {
            this.isDisable = false;
          });
      }
    },
    goSpace (item) {
      const { couponType } = item;
      this.$store.commit('coupon/setSelectCupon', true);
      this.$store.commit('coupon/setCuponInfo', item);
      if (couponType === 'All') {
        this.$router.push({ name: 'SpaceInsert' }).catch(() => {});
      } else if (couponType === 'Store') {
        this.$router.push({ name: 'SpaceInsertStore' }).catch(() => {});
      } else if (couponType === 'Car') {
        this.$router.push({ name: 'SpaceInsertCar' }).catch(() => {});
      }
    },
    linkPlayer () {
      var spaceId = VueCookies.get('spaceId');
      if (spaceId != null) {
        router.push({ name: 'Player' });
      } else {
        router.push({ name: 'MySpace' });
      }
    },
    clickPre () {
      history.back();
    },

    endDay (value) {
      var today = dayjs().format('YYYY-MM-DD');
      var expireDate = dayjs(value);
      var endDay = expireDate.diff(today, 'day', true);
      return Math.floor(endDay);
    },

    async getMyCouponList () {
      var userId = VueCookies.get('userId');
      var aToken = VueCookies.get('aToken');
      var headers = { 'X-AUTH-TOKEN': aToken };

      await axios
        .post('/api/product/getUserCouponList', { userId }, { headers })
        .then(res => {
          if (res.data.resultCd === '0000') {
            this.couponList = res.data.result;
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    endDateTime (value) {
      // 쿠폰 만료일 -1일
      // return dayjs(value).subtract(1, 'day').format("YY.MM.DD");
      return dayjs(value).format('YY.MM.DD');
    },
    dateTime (value) {
      return dayjs(value).format('YY.MM.DD');
    },
    showAside () {
      this.asideStatus = true;
    },
    mobileAlarm () {
      this.$router.push({ name: 'Notice0' });
    },
    closeAlertModal () {
      this.isAlertModal = false;
      this.getMyCouponList();
    }
  },
  mounted () {
    this.getMyCouponList();
  },
  computed: {
    getCouponList () {
      const registerDesc = orderBy(this.couponList, ['registDate'], ['desc']);
      return registerDesc;
    }
  }
});
</script>
<style src="@/assets/css/content2.css"></style>
<style src="@/assets/css/font.css"></style>
<style scoped>
.coupon_input_wrap {
  display: flex;
  flex-direction: row;
}

.coupon_input_box {
  width: 476px;
  height: 54px;
}

.add_btn {
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.24);
  background: rgba(255, 255, 255, 0.04);
  font-size: 16px;
  color: var(--white);
  line-height: 32px;
  padding: 10px 24px;
  margin-left: 10px;
}

.non_cupoon_error_text {
  display: inline-block;
  height: 25px;
}

.cupoon_error_text {
  color: var(--E85454);
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  margin-top: 4px;
  display: inline-block;
  height: 24px;
}

.coupon_txt .dday {
  border-radius: 40px;
  background: rgba(255, 45, 85, 0.2);
  color: var(--secondary);
  font-size: 14px;
  font-weight: 700;
  padding: 2px 8px;
  line-height: 17px;
}

.cupon_info_group {
  display: flex;
  justify-content: space-between;
}

.coupon_txt .cupon_info_group .date_info {
  color: rgba(255, 255, 255, 0.6);
  font-size: 16px;
  margin-bottom: 0;
}

.coupon_use_btn {
  background-color: var(--secondary);
  color: var(--white);
  font-size: 15px;
  line-height: 19px;
  padding: 12px 24px;
  font-weight: 700;
}

.coupon_img_box {
  margin-top: 21px;
}

@media (max-width: 500px) {
  .coupon_input_wrap {
    width: 100%;
    justify-content: space-between;
    margin-top: 5px;
  }

  .coupon_use_btn {
    padding: 4px 10px;
    font-size: 12px;
    line-height: 21px;
  }

  .coupon_txt .dday {
    display: inline;
  }

  .coupon_input_box {
    width: 80%;
  }

  .add_btn {
    width: 18%;
    font-size: 14px;
    line-height: 32px;
    padding: 10px 16px;
    margin-left: 0;
  }

  .coupon_txt .cupon_info_group .date_info {
    font-size: 12px;
    line-height: 31px;
  }
}

@media (max-width: 360px) {
  .add_btn {
    padding: 8px 14px;
  }
}
</style>
